import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import './index.css';

const Home = lazy(() => import('./pages/HomePage'));
const Signup = lazy(() => import('./pages/SignupPage.js'));
const Login = lazy(() => import('./pages/LoginPage'));
const CreateBusinessPage = lazy(() => import('./pages/CreatePage.js'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));

const App = () => {
    return (
      <Router>
        <div className="flex flex-col min-h-screen">
          <main className="flex-grow">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/page/create" element={<CreateBusinessPage />} />
                <Route path="/profile/:userId" element={<Profile />} />
              </Routes>
            </Suspense>
          </main>
        </div>
      </Router>
    );
};

const Profile = () => {
  const { userId } = useParams();
  return <ProfilePage userId={userId} />;
};

export default App;
