import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBE8FgNKaGJH-amzWWx3j0vh_Kb5VEoVKM",
  authDomain: "lorley.com",
  databaseURL: "https://lorley-148fd-default-rtdb.firebaseio.com",
  projectId: "lorley-148fd",
  storageBucket: "lorley-148fd.appspot.com",
  messagingSenderId: "596717675955",
  appId: "1:596717675955:web:224441132b05ff087a17c6",
  measurementId: "G-YJZ2KWPM3M"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);


export { app, db, analytics };